import React, { useState, useReducer } from "react";
import { Table, Row, Col, Form, Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { FaSearch } from "react-icons/fa";
import { TENANT_BUCKET, HISTORY_TABLE_FIELD } from "../../../../common/constants";
// import { useNavigate } from "react-router-dom";
import { useMiddletier } from "../../../../common/middletier";
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Loading from '../../../../components/Loading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';




const HISTORY_ACTION = {
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',
  SET_SELECTED_TRANSACTION_TYPE: 'SET_SELECTED_TRANSACTION_TYPE',
  SET_SELECTED_START_DATE: 'SET_SELECTED_START_DATE',
  SET_SELECTED_END_DATE: 'SET_SELECTED_END_DATE',
  SET_HISTORY_TRANSACTION: 'SET_HISTORY_TRANSACTION',
}
//  [{ id: 'DEPOSIT_WITHDRAW', name: 'deposit_withdrawal' }, { id: 'TRANSFER', name: 'transfer' }, { id: 'BETTING_SUMMARY', name: 'betting_summary' }, { id: 'PROMOTION_APPLY', name: 'promotion_apply' }, { id: 'REBATE', name: 'rebate' }]

const initialHistoryData = {
  transactionType: [{ id: 'DEPOSIT_WITHDRAW', name: 'deposit_withdrawal' }, { id: 'TRANSFER', name: 'transfer' }, { id: 'BETTING_SUMMARY', name: 'betting_summary' }, { id: 'PROMOTION_APPLY', name: 'promotion_apply' }, { id: 'REBATE', name: 'rebate' }],
  selectedtransactionType: { id: 'DEPOSIT_WITHDRAW', name: 'deposit_withdrawal' },
  selectedStartDate: '',
  selectedEndDate: '',
  historyTransaction: []
}

const historyReducer = (state, action) => {
  switch (action.type) {

    case HISTORY_ACTION.SET_TRANSACTION_TYPE: {
      const transactionType = [...action.payload]
      return { ...state, transactionType, selectedtransactionType: transactionType.length > 0 ? transactionType[0] : '' };
    }

    case HISTORY_ACTION.SET_SELECTED_TRANSACTION_TYPE: {
      const selectedtransactionType = state.transactionType.find((transactionType) => transactionType.id === action.payload)
      return { ...state, selectedtransactionType };
    }

    case HISTORY_ACTION.SET_SELECTED_START_DATE: {
      return { ...state, selectedStartDate: action.payload };
    }

    case HISTORY_ACTION.SET_SELECTED_END_DATE: {
      return { ...state, selectedEndDate: action.payload };
    }

    case HISTORY_ACTION.SET_HISTORY_TRANSACTION: {
      return { ...state, historyTransaction: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


function History() {
  const appDispatch = useStoreDispatch()
  const { t } = useTranslation();
  const { query } = useMiddletier();
  const appState = useStoreState();
  const [historyState, historyDispatch] = useReducer(historyReducer, initialHistoryData)
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [currentDetails, setCurrentDetails] = useState(null);
  // const [selected, setSelected] = React.useState();
  // const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  dayjs.extend(utc);
  dayjs.extend(timezone);

  // const _timezone = appState.timezone ?? 'Asia/Kuala_Lumpur';

  const itemsPerPage = 20;

  const maxPage = Math.ceil(historyState.historyTransaction?.length / itemsPerPage);

  const today = dayjs().tz(appState.timezone).format('YYYY-MM-DD');

  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');

    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }

    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });

    return formattedAmount;
  };

  const onChangeTransactionType = (newValue) => {
    historyDispatch({ type: HISTORY_ACTION.SET_SELECTED_TRANSACTION_TYPE, payload: newValue })
    historyDispatch({ type: HISTORY_ACTION.SET_HISTORY_TRANSACTION, payload: [] })
    setCurrentPage(1)
  }

  const onChangeStartDate = (startDate, endDate) => {
    // console.log('startDate:',dayjs(startDate).isAfter(endDate))

    if (dayjs(startDate).isAfter(today)) {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'Start date cannot be after today.', typeAlert: 'error' }
      });
      return;
    }

    if (dayjs(startDate).isAfter(endDate)) {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'Start date cannot be after end date.', typeAlert: 'error' }
      });
      return;
    } else {
      historyDispatch({ type: HISTORY_ACTION.SET_SELECTED_START_DATE, payload: startDate })
    }

  }

  const onChangeEndDate = (startDate, endDate, today) => {

    if (dayjs(endDate).isAfter(today)) {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'End date cannot be after today.', typeAlert: 'error' }
      });
      return;
    }
    if (dayjs(endDate).isBefore(startDate)) {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'End date cannot be before start date.', typeAlert: 'error' }
      });
      return;
    }

    historyDispatch({ type: HISTORY_ACTION.SET_SELECTED_END_DATE, payload: endDate })
  }

  const handleSubmit = () => {

    if (typeof appState.user?.username !== 'undefined') {
      let method, params, attributes, getTransactionData, filterValue

      // GET FROM DATE AND END DATE
      const formDate = historyState.selectedStartDate ? (dayjs(historyState.selectedStartDate).tz(appState.timezone, true).startOf('day') === 'Invalid date' ? '' : dayjs(historyState.selectedStartDate).tz(appState.timezone, true).startOf('day')) : '';
      const endDate = historyState.selectedEndDate ? (dayjs(historyState.selectedEndDate).tz(appState.timezone, true).endOf('day') === 'Invalid date' ? '' : dayjs(historyState.selectedEndDate).tz(appState.timezone, true).endOf('day')) : dayjs().tz(appState.timezone, true).endOf('day');

      // console.log('formDate: ', formDate)
      // console.log('endDate: ', endDate)


      // CHECK TRANSACTION TYPE
      if (historyState.selectedtransactionType?.id === 'TRANSFER') {
        method = 'member_transactions'
        filterValue = {
          where: { member: appState.user?.username, transaction_type: ["TRANSFER_OUT", "TRANSFER_IN", "FRIEND_TRANSFER"], ...(formDate !== '' && endDate !== '' ? { transaction_at: { "$between": [formDate, endDate] } } : {}) },
          order: [['transaction_at', 'DESC']]
        };
        params = [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ]
        attributes = ['id', 'transaction_at', 'amount', ['transaction_type', 'transaction_type'], ['game_provider_label', 'game_provider'], ['status_label', 'status'], 'friend']
      }

      else if (historyState.selectedtransactionType?.id === 'DEPOSIT_WITHDRAW') {
        method = 'member_transactions'
        filterValue = {
          where: { member: appState.user?.username, transaction_type: ["DEPOSIT", "WITHDRAW"], ...(formDate !== '' && endDate !== '' ? { transaction_at: { "$between": [formDate, endDate] } } : {}) },
          order: [['transaction_at', 'DESC']]
        };
        params = [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ]
        attributes = ['id', 'transaction_at', 'amount', ['transaction_type', 'transaction_type'], ['game_provider_label', 'game_provider'], ['status_label', 'status'], 'remark']

      }

      else if (historyState.selectedtransactionType?.id === 'BETTING_SUMMARY') {
        method = 'getBettingSummary'

        if (formDate !== '' && endDate !== '') {
          params = [
            { code: 'fromDate', graphqlType: 'Date', required: false, value: historyState.selectedStartDate ? (dayjs(historyState.selectedStartDate).tz(appState.timezone).startOf('day').format('YYYY-MM-DD') === 'Invalid date' ? '' : dayjs(historyState.selectedStartDate).tz(appState.timezone).startOf('day').format('YYYY-MM-DD')) : '' },
            { code: 'toDate', graphqlType: 'Date', required: false, value: historyState.selectedEndDate ? (dayjs(historyState.selectedEndDate).tz(appState.timezone).endOf('day').format('YYYY-MM-DD') === 'Invalid date' ? '' : dayjs(historyState.selectedEndDate).tz(appState.timezone).endOf('day').format('YYYY-MM-DD')) : dayjs().tz(appState.timezone).endOf('day').format('YYYY-MM-DD') },
          ]
        } else {
          params = []
        }

        attributes = []

      }

      else if (historyState.selectedtransactionType?.id === 'PROMOTION_APPLY') {

        method = 'promotion_applications'
        filterValue = {
          where: { member: appState.user?.username, ...(formDate !== '' && endDate !== '' ? { application_datetime: { "$between": [formDate, endDate] } } : {}) },
          order: [['application_datetime', 'DESC']]
        };
        params = [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ]
        attributes = ['id', ['promotion_label', 'promotion'], 'application_datetime', ['game_provider_label', 'game_provider'], 'target_amount', 'deposit_amount', ['status_label', 'status'], ['bonus_amount', 'promo_bonus_amount'], 'remark']

      }
      else if (historyState.selectedtransactionType?.id === 'REBATE') {
        method = 'rebate_releases'
        filterValue = {
          where: { member: appState.user?.username, ...(formDate !== '' && endDate !== '' ? { rebate_start_date: { "$between": [formDate, endDate] } } : {}) },
          order: [['rebate_start_date', 'DESC']]
        };
        params = [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ]
        attributes = ['id', 'release_date', 'release_amount', 'rebate_start_date', 'rebate_end_date', ['status_label', 'status']]
      }

      else {
        historyDispatch({ type: HISTORY_ACTION.SET_HISTORY_TRANSACTION, payload: [] })
      }



      if (method) {
        setLoading(true)
        query({
          method,
          params,
          attributes

        }).then(({ data }) => {

          setLoading(false)

          if (historyState.selectedtransactionType?.id === 'TRANSFER') {

            getTransactionData = data[method]?.map((item) => {
              let transferFrom = (item.transaction_type === 'FRIEND_TRANSFER' ? (item?.amount > 0 ? `${item?.friend ?? ''}` : `${appState?.user?.username ?? ''}`) : (item.transaction_type === 'TRANSFER_IN' ? item.game_provider : 'Main Wallet'))
              let transferTo = (item.transaction_type === 'FRIEND_TRANSFER' ? (item?.amount > 0 ? `${appState?.user?.username ?? ''}` : `${item?.friend ?? ''}`) : (item.transaction_type === 'TRANSFER_IN' ? 'Main Wallet' : item.game_provider))
              console.log('transferFrom', transferFrom)
              console.log('transferTo', transferTo)

              return { transaction_date: dayjs(item.transaction_at).tz(appState?.timezone).format('YYYY-MM-DD  HH:mm:ss'), from: transferFrom, to: transferTo, amount: formatAmount(item.amount ?? 0, appState.currencyFormat.decimal_point), transaction_status: item.status };
            })

          } else if (historyState.selectedtransactionType?.id === 'DEPOSIT_WITHDRAW') {

            getTransactionData = data[method]?.map((item) => {
              return { ticket_id: item.id, payment_type: item.transaction_type, transaction_date: dayjs(item.transaction_at).tz(appState?.timezone).format('YYYY-MM-DD  HH:mm:ss'), amount: formatAmount(item.amount ?? 0, appState.currencyFormat.decimal_point), transaction_status: item.status, remark: item.remark };
            })

          }
          else if (historyState.selectedtransactionType?.id === 'BETTING_SUMMARY') {

            getTransactionData = data[method]?.map((item) => {
              return { game_provider: item.game_provider_name, bet_count: item.bet_count, bet_amount: formatAmount(item.bet_amount ?? 0, appState.currencyFormat.decimal_point), valid_bet_amount: formatAmount(item.turnover ?? 0, appState.currencyFormat.decimal_point), promo_turnover: formatAmount(item.promo_turnover ?? 0, appState.currencyFormat.decimal_point), turnover_without_promo: formatAmount(item.turnover_without_promo ?? 0, appState.currencyFormat.decimal_point), total_bonus: formatAmount(item.bonus_amount ?? 0, appState.currencyFormat.decimal_point), total_rebate: formatAmount(item.release_amount ?? 0, appState.currencyFormat.decimal_point) };
            })

          }
          else if (historyState.selectedtransactionType?.id === 'PROMOTION_APPLY') {

            getTransactionData = data[method]?.map((item) => {
              return { ticket_id: item.id, promotion_name: item.promotion, transaction_date: dayjs(item.application_datetime).tz(appState?.timezone).format('YYYY-MM-DD  HH:mm:ss'), to: item.game_provider, transfer_amount: formatAmount(item.deposit_amount ?? 0, appState.currencyFormat.decimal_point), promo_bonus_amount: formatAmount(item.promo_bonus_amount ?? 0, appState.currencyFormat.decimal_point), target_amount: formatAmount(item.target_amount ?? 0, appState.currencyFormat.decimal_point), transaction_status: item.status, remark: item.remark };
            })

          }
          else if (historyState.selectedtransactionType?.id === 'REBATE') {

            getTransactionData = data[method]?.map((item) => {
              return { release_date: dayjs(item.release_date).tz(appState?.timezone).format('YYYY-MM-DD  HH:mm:ss'), release_amount: formatAmount(item.release_amount ?? 0, appState.currencyFormat.decimal_point), rebate_start_date: dayjs(item.rebate_start_date).tz(appState?.timezone).format('YYYY-MM-DD'), rebate_end_date: dayjs(item.rebate_end_date).tz(appState?.timezone).format('YYYY-MM-DD'), status: item.status };
            })

          }
          // console.log('getTransactionData', getTransactionData)
          historyDispatch({ type: HISTORY_ACTION.SET_HISTORY_TRANSACTION, payload: getTransactionData })


        }).catch((error) => {
          setLoading(false)
          console.error(error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })

      }
    } else {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: 'cannot find member', typeAlert: 'error' }
      });
      historyDispatch({ type: HISTORY_ACTION.SET_HISTORY_TRANSACTION, payload: [] })
    }
  }


  const createPagination = () => {
    let pages = [];
    for (let i = 1; i <= maxPage; i++) {
      pages.push(<button onClick={() => setCurrentPage(i)} key={i} className={`paginationNumber ${currentPage === i && 'active color_active'} `}>{i}</button>);
    }
    return pages;
  }

  const nextPage = () => {
    setCurrentPage((oldPage) => Math.min(oldPage + 1, maxPage));
  };

  const previousPage = () => {
    setCurrentPage((oldPage) => Math.max(oldPage - 1, 1));
  };

  const onClickDetailsDialog = (item) => {
    setCurrentDetails(item)
    setShowDetailsDialog(true)
  };


  return (
    <>
      <div className="history_body">
        {isDesktopOrLaptop && <div className="font_h2 color_primary wallet_container_title">{t('history')}</div>}
        <div className="wallet_container_wrap">
          {/* {isDesktopOrLaptop ? */}
          <Form className="history_form">
            <Row>
              <Form.Group className="mb-3" >
                <Form.Label>{t('transaction_type')} :
                  <span className="form_required_input">* {t('required_select')}</span>
                </Form.Label>
                <div className="transactionTypeTab">
                  {historyState.transactionType.map(function (item, index) {
                    return (
                      <span key={item.id} className={`transactionType_selection ${historyState.selectedtransactionType?.id === item.id ? 'color_active active' : ''}`} onClick={() => onChangeTransactionType(item.id)}>
                        {(t(item.name))}
                      </span>
                    )
                  })}
                </div>
              </Form.Group>
            </Row>
            {isDesktopOrLaptop ?
              <Row>
                <Col lg={4}>
                  <Form.Group as={Row}  >
                    <Form.Label column lg={4} className="history_date_label">{t('start_date')}:</Form.Label>
                    <Col lg={7} className="px-lg-0">
                      {/* <Form.Control
                        type="date"
                        placeholder={t('start_date')}
                        className="font_h6 input_startDate"
                        aria-label="startDate"
                        value={historyState.selectedStartDate ?? ''}
                        onChange={(evt) => { onChangeStartDate(evt.target.value, historyState.selectedEndDate) }}
                      /> */}
                      <DatePicker
                        selected={historyState.selectedStartDate}
                        onChange={(date) => onChangeStartDate(date, historyState.selectedEndDate)}
                        dateFormat="yyyy-MM-dd"
                        className="font_h5 input_endDate form-control"
                        placeholderText={'YYYY-MM-DD'}
                        aria-label="startDate"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group as={Row} >
                    <Form.Label column lg={4} className="history_date_label">{t('end_date')}:</Form.Label>
                    <Col lg={7} className="px-lg-0">
                      {/* <Form.Control
                        type="date"
                        placeholder={t('end_date')}
                        className="font_h6 input_endDate"
                        aria-label="endDate"
                        value={historyState.selectedEndDate ?? ''}
                        onChange={(evt) => { onChangeEndDate(historyState.selectedStartDate, evt.target.value, today) }}
                      /> */}

                      <DatePicker
                        selected={historyState.selectedEndDate}
                        onChange={(date) => onChangeEndDate(historyState.selectedStartDate, date, today)}
                        dateFormat="yyyy-MM-dd"
                        className="font_h5 input_endDate form-control"
                        placeholderText={'YYYY-MM-DD'}
                        aria-label="end_date"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg={{ span: 2, offset: 2 }}>
                  <button className="font_button color_button wallet_btnSubmit btnSearch" type="button" onClick={handleSubmit}>
                    {t('search')}
                  </button>
                </Col>
              </Row> :
              <Row>
                <Col> <Form.Group as={Row}  >

                  <Form.Label column className="history_date_label w-25">{t('start_date')}:</Form.Label>
                  <Col xs={9} className="px-xs-0">
                    {/* <Form.Control
                      type="date"
                      placeholder={t('start_date')}
                      className="font_h6 input_startDate"
                      aria-label="startDate"
                      value={historyState.selectedStartDate ?? ''}
                      onChange={(evt) => { onChangeStartDate(evt.target.value, historyState.selectedEndDate) }}
                    /> */}
                    <DatePicker
                      selected={historyState.selectedStartDate}
                      onChange={(date) => onChangeStartDate(date, historyState.selectedEndDate)}
                      dateFormat="yyyy-MM-dd"
                      className="font_h5 input_endDate form-control"
                      placeholderText={'YYYY-MM-DD'}
                      aria-label="startDate"
                    />
                  </Col>
                </Form.Group></Col>
                <Col> <Form.Group as={Row} >
                  <Form.Label column className="history_date_label w-25">{t('end_date')}:</Form.Label>
                  <Col xs={9} className="px-xs-0">
                    {/* <Form.Control
                      type="date"
                      placeholder={t('end_date')}
                      className="font_h6 input_endDate"
                      aria-label="endDate"
                      value={historyState.selectedEndDate ?? ''}
                      onChange={(evt) => { onChangeEndDate(historyState.selectedStartDate, evt.target.value, today) }}
                    /> */}
                    <DatePicker
                      selected={historyState.selectedEndDate}
                      onChange={(date) => onChangeEndDate(historyState.selectedStartDate, date, today)}
                      dateFormat="yyyy-MM-dd"
                      className="font_h5 input_endDate form-control"
                      placeholderText={'YYYY-MM-DD'}
                      aria-label="end_date"
                    />
                  </Col>
                </Form.Group></Col>

                <Col xs={{ span: 2 }}>
                  <button className="font_button color_button wallet_btnSubmit btnSearch" type="button" onClick={handleSubmit}>
                    <FaSearch />
                  </button>
                </Col>
              </Row>
            }
          </Form>

          {/* <Tabs
               defaultActiveKey={historyState.selectedTransactionType?.id}
               id="wallet_history_tab"
               className="mb-3 wallet_history_tab tab_color font_tab"
               onSelect={(key) => onChangeTransactionType(key)}
             >
               {historyState.transactionType.map(function (item, index) {
                 return (
                   <Tab key={item.id} eventKey={item.id} title={t(item.name)} tabClassName="wallet_history_tabSelection">
                     <Container>
                       <Form className="history_form">
                         <Row>
                           <Col> <Form.Group as={Row}  >

                             <Form.Label column className="history_date_label w-25">{t('start_date')}:</Form.Label>
                             <Col xs={9} className="px-xs-0">
                               <Form.Control
                                 type="date"
                                 placeholder={t('start_date')}
                                 className="font_h6 input_startDate"
                                 aria-label="startDate"
                                 value={historyState.selectedStartDate ?? ''}
                                 onChange={(evt) => { onChangeStartDate(evt.target.value, historyState.selectedEndDate) }}
                               /></Col>
                           </Form.Group></Col>
                           <Col> <Form.Group as={Row} >
                             <Form.Label column className="history_date_label w-25">{t('end_date')}:</Form.Label>
                             <Col xs={9} className="px-xs-0">
                               <Form.Control
                                 type="date"
                                 placeholder={t('end_date')}
                                 className="font_h6 input_endDate"
                                 aria-label="endDate"
                                 value={historyState.selectedEndDate ?? ''}
                                 onChange={(evt) => { onChangeEndDate(historyState.selectedStartDate, evt.target.value, today) }}
                               /></Col>
                           </Form.Group></Col>

                           <Col xs={{ span: 2 }}>
                             <button className="font_button color_button wallet_btnSubmit btnSearch" type="button" onClick={handleSubmit}>
                               <FaSearch />
                             </button>
                           </Col>
                         </Row>
                       </Form>
                     </Container>
                   </Tab>)
               })}
             </Tabs> */}


          {
            historyState.historyTransaction?.length ? (
              <>
                <div className="wallet_history_table_wrap">
                  <Table className='wallet_history_table '>
                    <thead className='color_secondary'>

                      <tr>

                        {

                          HISTORY_TABLE_FIELD[historyState.selectedtransactionType?.id]?.selectedTableColumn.map(function (item, index) {
                            return (
                              <th key={item}>{t(item)}</th>
                            )
                          })
                        }
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        historyState.historyTransaction.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map(function (item, rowindex) {
                          const _item = HISTORY_TABLE_FIELD[historyState.selectedtransactionType?.id]
                          return (
                            <tr key={rowindex}>

                              {_item.selectedTableColumn.map((keyCell, cellIndex) => {
                                if (keyCell === 'paymentType_transactionDate') {
                                  return (
                                    <td key={cellIndex}>
                                      {item.payment_type} <br />
                                      {item.transaction_date}
                                      {/* {dayjs(item.transaction_date).tz(appState?.timezone).format('YYYY-MM-DD  HH:mm:ss')}<br />
                                    {dayjs(item.transaction_date).tz(appState?.timezone).format('YYYY-MM-DDTHH:mm:ss')} */}

                                      {/* without timezone: {dayjs(item.transaction_date).format('YYYY-MM-DD HH:mm:ss')} <br /> */}

                                    </td>
                                  );
                                } else if (keyCell === 'promotion') {
                                  return (
                                    <td key={cellIndex}>
                                      <div>{item.promotion_name} </div>
                                      <div>{item.transaction_date}</div>
                                    </td>
                                  );
                                }
                                // else if (keyCell === 'transaction_date') {
                                //   return (
                                //     <td key={cellIndex}>
                                //     {dayjs(item[keyCell])?.tz(_timezone)}
                                //     {dayjs(item[keyCell])?.tz(_timezone).format('YYYY-MM-DD HH:mm:ss')}
                                //   </td>
                                //   );
                                // }
                                else if (keyCell === 'transaction_status') {
                                  return (
                                    <td key={cellIndex}>
                                      {t(item[keyCell]?.toLowerCase().replace(" ", "_"))}
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td key={cellIndex}>
                                      {item[keyCell]}
                                    </td>
                                  );
                                }

                              })}
                              <td>
                                <div onClick={() => onClickDetailsDialog(item)} >{t('view')}</div>
                                {(historyState.selectedtransactionType?.id === "DEPOSIT_WITHDRAW" || historyState.selectedtransactionType?.id === "PROMOTION_APPLY") && (<div>{item['remark'] ?? '-'}</div>)}
                              </td>


                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
                </div>
                <div className="history_current_showing">
                  Showing {Math.min((currentPage - 1) * itemsPerPage + 1, historyState.historyTransaction?.length)} to {Math.min(currentPage * itemsPerPage, historyState.historyTransaction?.length)} of {historyState.historyTransaction?.length}
                </div>
                <div className="history_paginationButton">
                  <button onClick={previousPage}>Previous</button>
                  {createPagination()}
                  <button onClick={nextPage}>Next</button>
                </div>
              </>) : <> <div className="history_noRecord_wrap"><img src={`${TENANT_BUCKET}/icon/noRecord.png`} alt="no record icon" /><label>{t('no_record')}</label></div> </>
          }
        </div>
        {(currentDetails) && (<Modal show={showDetailsDialog}
          onHide={() => setShowDetailsDialog(false)}
          id="historyDetailsDialog"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
              {t(historyState.selectedtransactionType?.name)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {

              HISTORY_TABLE_FIELD[historyState.selectedtransactionType?.id]?.allAttr.map(function (item, index) {
                if (item === 'transaction_status') {
                  return (
                    <div className="historyDetails_content_row" key={`${historyState.selectedtransactionType?.id}${item}${currentDetails[item]}`}>
                      <label>{t(item)}</label>
                      <p>{t(currentDetails[item]?.toLowerCase().replace(" ", "_"))}</p>
                    </div>
                  )
                } else if (item === 'remark') {
                  return (
                    <div className="historyDetails_content_row" key={`${historyState.selectedtransactionType?.id}${item}${currentDetails[item]}`}>
                      <label>{t(item)}</label>
                      <p>{currentDetails[item] ?? '-'}</p>
                    </div>
                  )
                } else {
                  return (
                    <div className="historyDetails_content_row" key={`${historyState.selectedtransactionType?.id}${item}${currentDetails[item]}`}>
                      <label>{t(item)}</label>
                      <p>{currentDetails[item]}</p>
                    </div>
                  )
                }

              })
            }
          </Modal.Body>
        </Modal>
        )}
      </div>
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      {isLoading && (<Loading />)}

    </>
  );
}



export default History;
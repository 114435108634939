import React, { useEffect, useState, useReducer, useRef } from "react";
import { Row, Col, Form, Modal, Accordion, OverlayTrigger, Popover } from "react-bootstrap";
import { FaExchangeAlt, FaChevronDown } from "react-icons/fa";

import { BiSortAlt2 } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useMiddletier } from "../../../../common/middletier";
import Loading from '../../../../components/Loading';
// import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
// import { FaInfoCircle } from "react-icons/fa";
import { TENANT_BUCKET, GAME_MENU_DATA } from '../../../../common/constants';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../../../common/storeContext'
import { useNavigate } from "react-router-dom"
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ProviderSelector from "../../../../components/ProviderSelector";

const TRANSFER_ACTION = {
    SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
    SET_MEMBER_GAME_PROVIDERS: 'SET_MEMBER_GAME_PROVIDERS',
    SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER',
    SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_TO_GAME_PROVIDER',
    SET_TRANSFER_AMOUNT: 'SET_TRANSFER_AMOUNT',
    SET_DEFAULT_PROMOTIONS: 'SET_DEFAULT_PROMOTIONS',
    SET_PROMOTIONS: 'SET_PROMOTIONS',
    SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
    SET_GAME_WALLETS: 'SET_GAME_WALLETS',
    SET_PROMOTION_CODE: 'SET_PROMOTION_CODE'
    // SET_FORCE_WITHDRAW: 'SET_FORCE_WITHDRAW'
    // SET_REFRESH_GAME_WALLET_BALANCE: 'SET_REFRESH_GAME_WALLET_BALANCE'
}

const initialTransferData = {
    gameProviders: [],
    memberGameProviders: [],
    selectedTransferFromGameProvider: '',
    selectedTransferToGameProvider: '',
    transferAmount: 0,
    defaultPromotions: [],
    promotions: [],
    selectedPromotion: { id: '', name: '' },
    gameWallets: [],
    promotionCode: ''
    // forceWithdraw: false
    // refreshGameWalletBalance: false
}

const transferReducer = (state, action) => {
    switch (action.type) {

        case TRANSFER_ACTION.SET_GAME_PROVIDERS: {
            const gameProviders = [...action.payload]
            return { ...state, gameProviders };
        }

        case TRANSFER_ACTION.SET_MEMBER_GAME_PROVIDERS: {
            const memberGameProviders = [...action.payload]
            console.log('member game', memberGameProviders)

            return { ...state, memberGameProviders };
        }

        case TRANSFER_ACTION.SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER: {
            // const selectedTransferFromGameProvider = action.payload === 'MAIN_WALLET' ? action.payload : (state.gameProviders.find((gameProviders) => gameProviders.id === action.payload))
            const selectedTransferFromGameProvider = action.payload
            console.log('onChangeTransferFrom', selectedTransferFromGameProvider)
            return { ...state, selectedTransferFromGameProvider };
        }

        case TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: {
            // const selectedTransferToGameProvider = action.payload === 'MAIN_WALLET' ? action.payload : (state.gameProviders.find((gameProviders) => gameProviders.id === action.payload))
            const selectedTransferToGameProvider = action.payload
            console.log('onChangeTransferTo', selectedTransferToGameProvider)

            return { ...state, selectedTransferToGameProvider };
        }

        case TRANSFER_ACTION.SET_TRANSFER_AMOUNT: {
            return { ...state, transferAmount: action.payload };
        }
        case TRANSFER_ACTION.SET_DEFAULT_PROMOTIONS: {
            const defaultPromotions = [...action.payload]
            return { ...state, defaultPromotions };
        }

        case TRANSFER_ACTION.SET_PROMOTIONS: {
            const promotions = [...action.payload]
            return { ...state, promotions };
        }

        case TRANSFER_ACTION.SET_SELECTED_PROMOTION: {
            const selectedPromotion = state.promotions.find((promotions) => promotions.id === action.payload)
            return { ...state, selectedPromotion: selectedPromotion ?? { id: '', name: '' } };
        }

        case TRANSFER_ACTION.SET_PROMOTION_CODE: {
            return { ...state, promotionCode: action.payload };
        }

        case TRANSFER_ACTION.SET_GAME_WALLETS: {
            const gameWallets = [...action.payload]
            return { ...state, gameWallets };
        }

        case TRANSFER_ACTION.SET_FORCE_WITHDRAW: {
            const forceWithdraw = action.payload
            return { ...state, forceWithdraw };
        }

        // case TRANSFER_ACTION.SET_REFRESH_GAME_WALLET_BALANCE: {
        //     return { ...state, refreshGameWalletBalance: action.payload ?? false };
        // }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


const Transfer = () => {
    const location = useLocation()
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const { t, i18n } = useTranslation();
    const fromContainerRef = useRef(null);
    const toContainerRef = useRef(null);
    const { queries, mutation, query } = useMiddletier()
    const [transferState, transferDispatch] = useReducer(transferReducer, initialTransferData)
    // const [showWithdrawActivePromotionDialog, setShowWithdrawActivePromotionDialog] = useState(false);
    const [showTransferGameProviderDialog, setShowTransferGameProviderDialog] = useState(false);
    const [showFromProviderSelection, setShowFromProviderSelection] = useState(false);
    const [showToProviderSelection, setShowToProviderSelection] = useState(false);
    const [transferGameProviderDetails, setTransferGameProviderDetails] = useState({});
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [isLoading, setLoading] = useState(false);
    let isProcessing = false;
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })



    const onChangeTransferFromGameProvider = (newValue) => {
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER, payload: newValue })
        setShowToProviderSelection(false)
        setShowFromProviderSelection(false)
    }

    const onChangeTransferToGameProvider = (newValue) => {
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: newValue })
        setShowToProviderSelection(false)
        setShowFromProviderSelection(false)
    }

    const onClickShowSelectProvider = (newValue) => {
        if (newValue === 'FROM') {
            if (showFromProviderSelection) {
                setShowFromProviderSelection(false)
            } else {
                setShowFromProviderSelection(true)
            }
            setShowToProviderSelection(false)
        } else {
            if (showToProviderSelection) {
                setShowToProviderSelection(false)
            } else {
                setShowToProviderSelection(true)
            }
            setShowFromProviderSelection(false)
        }
    }

    const onExchange = () => {
        const getTransferFromValue = transferState.selectedTransferFromGameProvider
        const getTransferToValue = transferState.selectedTransferToGameProvider

        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER, payload: getTransferToValue })
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: getTransferFromValue })
    }

    const onChangeTansferAmount = (newValue) => {
        transferDispatch({ type: TRANSFER_ACTION.SET_TRANSFER_AMOUNT, payload: newValue })
    }

    const onChangePromotion = (newValue) => {
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
    }

    const onChangePromotionCode = (newValue) => {
        transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTION_CODE, payload: newValue })
        // transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
    }

    const clearInput = () => {
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER, payload: '' })
        transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: '' })
        transferDispatch({ type: TRANSFER_ACTION.SET_TRANSFER_AMOUNT, payload: 0 })
        transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTION_CODE, payload: '' })
        //    transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: '' })

    }

    // CANCEL PROMOTION
    // const confirmCancelPromotion = () => {
    //     setLoading(true)
    //     // CHECK PROMOTION
    //     let existPromotion = transferState.memberGameProviders.find((e) => e.game_provider === transferState.selectedTransferFromGameProvider)

    //     mutation([{
    //         method: 'cancelPromotion',
    //         params: [{ code: 'id', graphqlType: 'String', required: true, value: existPromotion.promotion_application }],
    //         attributes: []

    //     }])
    //         .then(({ data }) => {
    //             console.log('data: ', data)
    //             setLoading(false)
    //             setAlertShow({ show: true, message: 'Cancel Promotion Successful', type: 'success' });
    //             return
    //         })
    //         .catch((error) => {
    //             setLoading(false)
    //             console.log('error: ', error)
    //         })
    // }

    // TRANSFER SUBMIT
    const handleSubmit = ({ forceWithdraw }) => {
        let method, params
        if (isLoading || isProcessing) return;

        isProcessing = true;
        setLoading(true)

        // IF TRANSFER TO MAIN WALLET 
        if (transferState.selectedTransferToGameProvider === 'MAIN_WALLET') {
            method = 'withdrawGamePrivoderByMember'
            params = [
                { code: 'gameProvider', graphqlType: 'String', required: true, value: transferState.selectedTransferFromGameProvider },
                { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
                { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(transferState.transferAmount === '' ? 0 : transferState.transferAmount) },
            ]
            // if (forceWithdraw) {
            //     params.push({ code: 'forceWithdraw', graphqlType: 'Boolean', required: false, value: true })
            // }
        } else {
            method = 'depositGamePrivoderByMember'

            params = [
                { code: 'gameProvider', graphqlType: 'String', required: true, value: transferState.selectedTransferToGameProvider },
                { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
                { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(transferState.transferAmount === '' ? 0 : transferState.transferAmount) },
            ]
            if (transferState.selectedTransferFromGameProvider !== 'MAIN_WALLET') {
                params.push({ code: 'fromGameProvider', graphqlType: 'String', required: false, value: transferState.selectedTransferFromGameProvider })
            }


            // CHECK PROMOTION
            if (transferState.selectedPromotion?.id !== '' || transferState.promotionCode !== '') {
                if (transferState.promotionCode !== '') {
                    const checkPromotionCode = transferState.promotionCode?.trim()
                    let promoCodeExist = transferState.promotions.find((promotions) => promotions.code === checkPromotionCode)
                    if (typeof promoCodeExist === 'undefined') {
                        setLoading(false)
                        isProcessing = false;
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: `Promotion Code[${checkPromotionCode}] Is NOT EXIST`, typeAlert: 'error' }
                        });

                        return
                    }
                    else {
                        params.push({ code: 'promotion', graphqlType: 'String', required: false, value: promoCodeExist.id })
                    }
                } else {
                    params.push({ code: 'promotion', graphqlType: 'String', required: false, value: transferState.selectedPromotion.id })
                }

            }


        }

        setTransferGameProviderDetails({ method, params })
        const checkPromotion = params.find((param) => param.code === 'promotion')
        // console.log('checkPromotion:', checkPromotion)
        if (typeof checkPromotion !== 'undefined') {
            query({
                method: 'getGamePrivoderBalanceByMember',
                params: [
                    { code: 'gameProvider', graphqlType: 'String', required: true, value: transferState.selectedTransferToGameProvider },
                    { code: 'member', graphqlType: 'String', required: true, value: appState?.user?.username },
                ],
                attributes: []
            })
                .then(({ data }) => {
                    if (data['getGamePrivoderBalanceByMember'] > 0) {
                        setLoading(false)
                        isProcessing = false;
                        setShowTransferGameProviderDialog(true)
                    } else {
                        transferGameProvider({ getMethod: method, getParams: params })
                    }



                }).catch((error) => {
                    console.log('error: ', error)
                    setLoading(false)
                    isProcessing = false;
                    if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                    }
                })


        } else {
            transferGameProvider({ getMethod: method, getParams: params })

        }



    };

    const transferGameProvider = ({ getMethod, getParams }) => {

        const method = getMethod ?? transferGameProviderDetails.method
        const params = getParams ?? transferGameProviderDetails.params

        mutation([{
            method,
            params,
            attributes: []

        }])
            .then(({ data }) => {
                console.log('data: ', data)
                setLoading(false)
                isProcessing = false;

                // IF STATUS IS APPROVED OR PENDING
                if ((method === 'withdrawGamePrivoderByMember' && data[method]) || data[method].status === 'APPROVED' || data[method].status === 'PENDING') {
                    if (data[method].status === 'PENDING') {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: 'Your transfer is being processed. The credit will be transferred to your game wallet shortly.', typeAlert: 'success' }
                        });
                    } else {

                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: 'Transfer Successful', typeAlert: 'success' }
                        });
                    }


                    clearInput()
                    setLoading(true)

                    queries([{
                        index: 'getAllGamePrivoderBalanceByMember',
                        method: 'getAllGamePrivoderBalanceByMember',
                        params: [],
                        attributes: []
                    },
                    {
                        index: 'member',
                        method: 'member',
                        params: [
                            { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                        ],
                        attributes: ['wallet_amount']
                    }, {
                        index: 'member_game_providers',
                        method: 'member_game_providers',
                        params: [
                            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState.user?.username, status: 'ACTIVE' }, order: [['game_provider_label', 'ASC']] } },
                        ],
                        attributes: ['id', 'game_provider_label', 'game_provider']
                    }])
                        .then(({ data }) => {
                            transferDispatch({ type: TRANSFER_ACTION.SET_FORCE_WITHDRAW, payload: false })
                            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })
                            // GET MEMBER GAME PROVIDER
                            const checkMemberGameProviderOptions = transferState.gameProviders?.map(provider => ({
                                ...provider,
                                options: provider.options
                                    .map(option => {
                                        const memberProvider = data['member_game_providers'].find(member => member.game_provider === option.code);
                                        return memberProvider ? { ...memberProvider } : null;
                                    })
                                    .filter(option => option !== null)
                            })).filter(provider => provider.options.length > 0);

                            transferDispatch({ type: TRANSFER_ACTION.SET_MEMBER_GAME_PROVIDERS, payload: checkMemberGameProviderOptions })
                            setLoading(false)
                            isProcessing = false;

                        }).catch((error) => {
                            console.log('error: ', error)
                            setLoading(false)
                            isProcessing = false;
                            if (error?.networkError?.statusCode === 401) {
                                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                            } else {
                                appDispatch({
                                    type: APP_STORE_ACTION.SHOW_ALERT,
                                    payload: { description: error.message.toString(), typeAlert: 'error' }
                                });
                            }
                        })
                }

                else {
                    const getErrorMsg = data[method].rejected_reason ? data[method].rejected_reason : (JSON.parse(data[method].response).errMsg)
                    console.log('getErrorMsg: ', getErrorMsg)
                    // setAlertShow({ show: true, message: getErrorMsg, type: 'error' });
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: getErrorMsg, typeAlert: 'error' }
                    });
                }


            })
            .catch((error) => {
                setLoading(false)
                isProcessing = false;
                console.log('error: ', error)

                // if (method === 'withdrawGamePrivoderByMember' && error.toString().includes("has active promotion")) {
                //     setShowWithdrawActivePromotionDialog(true);
                // } else {
                if (error?.networkError?.statusCode === 401) {
                    appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                    appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                    });
                }
                // }


            })
        setTransferGameProviderDetails({})
    }

    // const onClickRefreshBalance = () => {
    //     if (appState.loggedIn) {
    //         setLoading(true)
    //         const method = 'member'

    //         query({
    //             method,
    //             params: [
    //                 { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
    //             ],
    //             attributes: ['wallet_amount']

    //         }).then(({ data }) => {
    //             setLoading(false)
    //             appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data[method].wallet_amount })


    //         }).catch((error) => {
    //             setLoading(false)
    //             console.log('error: ', error)
    //             if (error?.networkError?.statusCode === 401) {
    //                 appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
    //             } else {
    //                 appDispatch({
    //                     type: APP_STORE_ACTION.SHOW_ALERT,
    //                     payload: { description: error.message.toString(), typeAlert: 'error' }
    //                 });
    //             }

    //         })
    //     }
    // }

    // const formatAmount = (amount, decimalPoint) => {
    //     const factor = Math.pow(10, decimalPoint);
    //     const truncatedAmount = Math.floor(amount * factor) / factor;
    //     const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
    //         minimumFractionDigits: decimalPoint,
    //         maximumFractionDigits: decimalPoint,
    //     });
    //     return formattedAmount;
    // }

    // const ProviderSelection = (props) => {

    //     const { type } = props
    //     let providerData = []
    //     providerData = type === 'FROM' ? transferState.memberGameProviders : transferState.gameProviders
    //     let getAnotherSelectedProvider = type === 'FROM' ? transferState.selectedTransferToGameProvider : transferState.selectedTransferFromGameProvider

    //     const onClickSelectProvider = (value) => {
    //         if (type === 'FROM') {
    //             onChangeTransferFromGameProvider(value)
    //         } else {
    //             onChangeTransferToGameProvider(value)
    //         }
    //         setShowToProviderSelection(false)
    //         setShowFromProviderSelection(false)
    //     }
    //     return (<>
    //         <div className="game_wallet_dropdown">
    //             {getAnotherSelectedProvider !== 'MAIN_WALLET' && (<div className="color_button main_wallet" onClick={() => onClickSelectProvider('MAIN_WALLET')}>
    //                 <div>{t('main_wallet')}</div>
    //                 <div className="gameWallet_amount">{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</div>
    //                 <OverlayTrigger
    //                     key={'right'}
    //                     placement={'right'}
    //                     overlay={
    //                         <Popover>
    //                             <Popover.Body className="icon_name">
    //                                 {t(`refresh_balance`)}
    //                             </Popover.Body>
    //                         </Popover>
    //                     }
    //                 >
    //                     <img src={`${TENANT_BUCKET}/icon/refresh_wallet_icon.png`} alt="" onClick={() => onClickRefreshBalance()} />
    //                 </OverlayTrigger>
    //             </div>)}

    //             <Accordion className='gameWallet_accordion' >
    //                 <SimpleBar style={{ maxHeight: '20rem' }}>
    //                     {providerData.map(item => {
    //                         const totalBalance = item.options.reduce((sum, option) => sum + (parseFloat(option.balance) || 0), 0);
    //                         return (
    //                             <Accordion.Item eventKey={`#${item.code}`} id={item.code} key={item.code}>
    //                                 <Accordion.Header>
    //                                     <div>{t(item.label)}</div>
    //                                     <div className="gameWallet_amount">{formatAmount(totalBalance, appState.currencyFormat.decimal_point)}</div>
    //                                     <div className="gameWallet_accordion_icon"><FaChevronDown /></div>
    //                                 </Accordion.Header>
    //                                 <Accordion.Body>
    //                                     <div className="game_wallet_dropdown_body">
    //                                         <table>
    //                                             <thead>
    //                                             </thead>
    //                                             <tbody>
    //                                                 {item.options?.filter(item => item.code !== getAnotherSelectedProvider).map(option => (
    //                                                     <tr key={option.code} onClick={() => onClickSelectProvider(option.code)}>
    //                                                         <td>{option.name}</td>
    //                                                         <td className="gameWallet_amount">{formatAmount(option.balance ?? 0, appState.currencyFormat.decimal_point)}</td>
    //                                                     </tr>
    //                                                 ))}
    //                                             </tbody>
    //                                         </table>
    //                                     </div>
    //                                 </Accordion.Body>
    //                             </Accordion.Item>
    //                         );
    //                     })}
    //                 </SimpleBar>
    //             </Accordion>

    //         </div>
    //     </>)
    // }


    // GET ALL ACTIVE GAME PROVIDER , MEMBER GAME PROVIDER AND PROMOTION
    useEffect(() => {

        const getFrom = new URLSearchParams(location.search).get('from') ?? '';
        if (getFrom !== '') {
            transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_TRANSFER_FROM_GAME_PROVIDER, payload: getFrom })
        }
        setLoading(true)

        if (typeof appState.user?.username !== 'undefined') {

            let transferqueries = [
                {
                    index: 'getGameTypes',
                    method: 'getGameTypes',
                    params: [
                        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
                    ],
                    attributes: [['type', 'code'], '_label']
                }, {
                    index: 'getGameProviders',
                    method: 'getGameProviders',
                    params: [
                        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: `ACTIVE` }, order: [['name', 'ASC']] } },
                    ],
                    attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
                },
                {
                    index: 'member_game_providers',
                    method: 'member_game_providers',
                    params: [
                        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { member: appState.user?.username, status: 'ACTIVE' }, order: [['game_provider_label', 'ASC']] } },
                    ],
                    attributes: ['id', 'game_provider_label', 'game_provider', "promotion_application", "locked"]
                }]

            if (appState?.gameWallet?.length > 0) {
                transferqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
            }


            queries(transferqueries)
                .then(({ data }) => {

                    // GET MEMBER GAME PROVIDER
                    // transferDispatch({ type: TRANSFER_ACTION.SET_MEMBER_GAME_PROVIDERS, payload: data['member_game_providers'] })


                    const checkAllGameProviderLock = data['getGameProviders']?.map(item => {
                        const checkGameProvider = data['member_game_providers']?.find(e => e.game_provider === item.code);
                        const checkGameProviderBalance = data['getAllGamePrivoderBalanceByMember']
                            ? data['getAllGamePrivoderBalanceByMember'].find(e => e.code === item.code)
                            : null;
                        return {
                            ...item,
                            locked: checkGameProvider ? checkGameProvider.locked : false,
                            balance: checkGameProviderBalance ? checkGameProviderBalance.balance : 0

                        };
                    });

                    // console.log('checkAllGameProviderLock: ', checkAllGameProviderLock)



                    // GET GAME PROVIDER
                    // transferDispatch({ type: TRANSFER_ACTION.SET_GAME_PROVIDERS, payload: checkAllGameProviderLock })



                    // GET PROMOTION 
                    // const getPromotionsData = data['getPromotions']?.map((item) => {
                    //     return { ...item, eligible_game_providers: JSON.parse(item.eligible_game_providers) };
                    // })

                    // console.log('getPromotionsData: ',getPromotionsData)
                    // transferDispatch({ type: TRANSFER_ACTION.SET_DEFAULT_PROMOTIONS, payload: data['getPromotions'] })

                    setLoading(false)


                    const groups = data['getGameTypes']
                    const providersData = checkAllGameProviderLock

                    const sortedData = groups.map(type => {
                        let getTypeName = GAME_MENU_DATA[type.code] ?? ''
                        const options = providersData
                            .filter(provider => provider._game_provider_game_types.some(gameType => gameType.type === type.code))
                            .map(provider => ({ code: provider.code, name: provider.name, locked: provider.locked, balance: provider.balance }));
                        return { code: type.code, label: getTypeName.name, options };
                    });

                    const checkMemberGameProviderOptions = sortedData.map(provider => ({
                        ...provider,
                        options: provider.options
                            .map(option => {
                                const memberProvider = data['member_game_providers'].find(member => member.game_provider === option.code);
                                return memberProvider ? {
                                    id: memberProvider?.id,
                                    code: memberProvider?.game_provider,
                                    name: memberProvider?.game_provider_label,
                                    locked: memberProvider?.locked,
                                    balance: option.balance,
                                    promotion_application: memberProvider?.promotion_application,
                                } : null;
                            })
                            .filter(option => option !== null)
                    })).filter(provider => provider.options.length > 0);

                    console.log('sortedData', sortedData)
                    console.log('mgp', checkMemberGameProviderOptions)
                    transferDispatch({ type: TRANSFER_ACTION.SET_GAME_PROVIDERS, payload: sortedData })
                    transferDispatch({ type: TRANSFER_ACTION.SET_MEMBER_GAME_PROVIDERS, payload: checkMemberGameProviderOptions })



                }).catch((error, data) => {
                    console.log('error: ', error)


                    setLoading(false)
                    // if (error.graphQLErrors) {
                    //     error.graphQLErrors.forEach((_error) => {
                    //         console.log('_error: ',_error)
                    //         if (_error.extensions.code === 'UNAUTHENTICATED') {
                    //             console.log('nonoo')
                    //             appDispatch({ type: APP_STORE_ACTION.LOGOUT })
                    //             setAlertShow({
                    //                 show: true, message: 'NO ACCESS TOKEN', type: 'error', onHide: () => {
                    //                     navigate('/')
                    //                 }
                    //             });
                    //         }
                    //     })
                    // }

                    if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                    }

                })
        }

        setLoading(false)

    }, [queries, appState.user?.username, appDispatch, navigate, location, appState?.gameWallet]);

    // GET PROMOTION
    useEffect(() => {
        if (transferState.selectedTransferFromGameProvider === 'MAIN_WALLET') {
            // setLoading(true)
            let getPromoParam = [
                { code: 'member', graphqlType: 'String', required: false, value: appState.user?.username },
                { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
                // { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
            ]
            // console.log('transferState.selectedTransferToGameProvider: ', transferState.selectedTransferToGameProvider)

            if (transferState.selectedTransferToGameProvider !== '') {
                getPromoParam.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: transferState.selectedTransferToGameProvider })
            }

            queries([
                {
                    index: 'getPromotions',
                    method: 'getPromotions',
                    params: getPromoParam,
                    attributes: []
                },])
                .then(({ data }) => {

                    transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTIONS, payload: data['getPromotions'] })
                    // setLoading(false)

                }).catch((error) => {
                    console.log('error: ', error)
                    setLoading(false)
                    if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                        appDispatch({
                            type: APP_STORE_ACTION.SHOW_ALERT,
                            payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                    }
                })

        } else {
            transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTIONS, payload: [] })
            transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTION_CODE, payload: '' })
            transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: { id: '', name: '' } })
        }
    }, [queries, transferState.selectedTransferToGameProvider, transferState.selectedTransferFromGameProvider, appState.user?.username, i18n.resolvedLanguage, appDispatch, navigate]);




    // WHEN CHANGE TRANSFER TO GAME PROVIDER WILL FILTER PROMOTION, IF TRANSFER FROM MAIN WALLET
    // useEffect(() => {
    //     if (transferState.selectedTransferFromGameProvider === 'MAIN_WALLET') {
    //         //     const filterPromotion = transferState.defaultPromotions?.filter((promotion) =>
    //         //     promotion.eligible_game_providers.some(
    //         //       (provider) => provider.code === transferState.selectedTransferToGameProvider
    //         //     )
    //         //   );

    //         const filterPromotion = transferState.defaultPromotions?.filter((promotion) =>
    //             promotion.eligible_game_providers.indexOf(transferState.selectedTransferToGameProvider) !== -1
    //         );

    //         transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTIONS, payload: filterPromotion })
    //     }
    //     else {
    //         transferDispatch({ type: TRANSFER_ACTION.SET_PROMOTIONS, payload: [] })
    //     }

    // }, [transferState.selectedTransferToGameProvider, transferState.selectedTransferFromGameProvider, transferState.defaultPromotions]);

    // CHECK FORM SUBMIT
    useEffect(() => {

        if (transferState.selectedTransferFromGameProvider !== '' && transferState.selectedTransferToGameProvider !== '' && (transferState.transferAmount > 0 || ((transferState.transferAmount === 0 || transferState.transferAmount === '0' || transferState.transferAmount === '') && (transferState.selectedPromotion?.id !== '' || transferState.promotionCode !== '')))) {
            setDisabledSubmit(false)
        }
        else {
            setDisabledSubmit(true)
        }

    }, [transferState.selectedTransferFromGameProvider, transferState.selectedTransferToGameProvider, transferState.transferAmount, transferState.selectedPromotion?.id, transferState.promotionCode])



    const handleClickOutside = (event) => {


        if (fromContainerRef.current && !fromContainerRef.current.contains(event.target)) {
            setShowFromProviderSelection(false)
        }
        if (toContainerRef.current && !toContainerRef.current.contains(event.target)) {
            setShowToProviderSelection(false)
        }

    };

    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className="transfer_body">
                {/* {isDesktopOrLaptop ? <div className="font_h2 color_primary wallet_container_title">{t('transfer_promo')}</div> : <GameWallet />} */}
                {isDesktopOrLaptop && (<div className="font_h2 color_primary wallet_container_title">{t('transfer_promo')}</div>)}
                <div className="wallet_container_wrap">
                    <Row>
                        <Col lg={7}>
                            <Form className="transfer_form">
                                <Form.Group className="form_input_wrap" >
                                    <div className="input_wrap_row ">
                                        <div className="transferFrom_col">
                                            <Form.Label>{t('transfer_from')}
                                                <span className="form_required_input">*</span>
                                            </Form.Label>
                                            {/* <Form.Select aria-label="transferFrom" className="input_transferFrom" value={transferState.selectedTransferFromGameProvider} onChange={(evt) => { onChangeTransferFromGameProvider(evt.target.value) }}>
                                                <option hidden></option>
                                                {transferState.selectedTransferToGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                                                {transferState.memberGameProviders?.map((provider) => (
                                                    <optgroup key={provider.label} label={t(provider.label)}>
                                                        {provider?.options?.filter(item => item.code !== transferState.selectedTransferToGameProvider).map((option) => (
                                                            <option key={option.code} value={option.code}>{option.name}</option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </Form.Select> */}
                                            <div className="selectProviderContainer" ref={fromContainerRef}>
                                                <div className="form-select" onClick={() => { onClickShowSelectProvider('FROM') }}>
                                                    {transferState.selectedTransferFromGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                                                        transferState.gameProviders.flatMap(item => item.options)
                                                            .find(option => option.code === transferState.selectedTransferFromGameProvider)
                                                            ?.name || ''
                                                    }</div>
                                                {showFromProviderSelection && (<ProviderSelector section={'TRANSFER'} onSelect={onChangeTransferFromGameProvider} data={transferState.memberGameProviders} currentProvider={transferState.selectedTransferFromGameProvider} otherProvider={transferState.selectedTransferToGameProvider} />)}
                                            </div>



                                        </div>
                                        <div className="font_h2 transferIcon_col">
                                            <div className="d-flex" onClick={() => onExchange()}>
                                                {isDesktopOrLaptop ? <FaExchangeAlt /> : <BiSortAlt2 />}
                                            </div>
                                        </div>
                                        <div className="transferTo_col">
                                            <Form.Label>{t('transfer_to')}
                                                <span className="form_required_input">* </span>
                                            </Form.Label>

                                            {/* <Form.Select aria-label="transferTo" className="input_transferTo" value={transferState.selectedTransferToGameProvider} onChange={(evt) => { onChangeTransferToGameProvider(evt.target.value) }}>
                                                <option hidden></option>
                                                {transferState.selectedTransferFromGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                                                {transferState.gameProviders.map((provider) => (

                                                    <optgroup key={provider.label} label={t(provider.label)}>
                                                        {provider?.options?.filter(item => item.code !== transferState.selectedTransferFromGameProvider).map((option) => (
                                                            <option key={option.code} value={option.code}>{option.name}</option>
                                                        ))}
                                                    </optgroup>
                                                ))}
                                            </Form.Select> */}
                                            <div className="selectProviderContainer" ref={toContainerRef}>
                                                <div className="form-select" onClick={() => { onClickShowSelectProvider('TO') }}>
                                                    {transferState.selectedTransferToGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                                                        transferState.gameProviders.flatMap(item => item.options)
                                                            .find(option => option.code === transferState.selectedTransferToGameProvider)
                                                            ?.name || ''
                                                    }</div>
                                                {showToProviderSelection && (<ProviderSelector section={'TRANSFER'} onSelect={onChangeTransferToGameProvider} data={transferState.gameProviders} currentProvider={transferState.selectedTransferToGameProvider} otherProvider={transferState.selectedTransferFromGameProvider} />)}
                                            </div>

                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group className="form_input_wrap" >
                                    <div className="input_wrap">
                                        <Form.Label>{t('transfer_amount')}
                                            <span className="form_required_input">* {t('required_fill_in')}</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            // step={1}
                                            // placeholder={t('transfer_amount_placeholder')}
                                            className="input_transferAmount"
                                            min={0}
                                            value={transferState.transferAmount === 0 ? '' : transferState.transferAmount}
                                            onChange={(evt) => { onChangeTansferAmount(evt.target.value) }}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            aria-label="transferAmount"

                                            onInput={(e) => {

                                                let value = appState.currencyFormat.decimal_point === 0
                                                    ? e.target.value.replace(/[^0-9]/g, '')
                                                    : e.target.value.replace(/[^0-9.]/g, '');

                                                if (value.split('.').length - 1 > 1) {
                                                    value = value.substring(0, value.lastIndexOf('.'));
                                                }

                                                const decimal_part = value.split(".")[1];
                                                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                                                    value = value.slice(0, -1);
                                                }
                                                e.target.value = value;
                                                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}

                                        />
                                        {/* <div className="info_input_wrap">
                                            <FaInfoCircle /> <span>{t('deposit_banktransfer_amount_placeholder')}</span>
                                        </div> */}
                                    </div>

                                </Form.Group>
                                {((transferState.selectedTransferFromGameProvider === "MAIN_WALLET" || transferState.selectedTransferFromGameProvider === "") && !appState.disablePromotion) && (
                                    <>
                                        <Form.Group className="form_input_wrap " >
                                            <div className="input_wrap">
                                                <Form.Label>{t('promotion')}
                                                    <span className="form_required_input">* {t('optional')}</span>
                                                </Form.Label>
                                                <Form.Select aria-label="transferPromotion" className="input_transferPromotion" value={transferState.selectedPromotion?.id} onChange={(evt) => { onChangePromotion(evt.target.value) }} disabled={transferState.promotionCode?.length > 0 ? true : false}>
                                                    {transferState.promotions?.filter(item => item.appear_in_website === true)?.length === 0 ? (<option hidden>No Promo Available</option>) : (<option></option>)}
                                                    {transferState.promotions?.filter(item => item.appear_in_website === true).map(function (item, index) {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.title ?? item.name} {item.code && `(${item.code})`}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="form_input_wrap " >
                                            <div className="input_wrap">
                                                <Form.Label>{t('promotion_code')}
                                                    <span className="form_required_input">* {t('optional')}</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="input_promotionCode"
                                                    value={transferState.promotionCode ?? ''}
                                                    onChange={(evt) => { onChangePromotionCode(evt.target.value) }}
                                                    aria-label="promotionCode"
                                                    disabled={transferState.selectedPromotion?.id !== '' ? true : false}
                                                />
                                            </div>
                                        </Form.Group></>)}

                                <input className="font_button color_button wallet_btnSubmit" type='button' onClick={() => handleSubmit({ forceWithdraw: false })} value={t('submit')} disabled={disabledSubmit} />
                            </Form>
                        </Col>
                    </Row>
                    {/* {isDesktopOrLaptop && <GameWallet />} */}
                </div>
            </div>
            {isLoading && (<Loading />)}
            {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title}/> */}
            {/* <Modal show={showWithdrawActivePromotionDialog} onHide={() => setShowWithdrawActivePromotionDialog(false)} centered id="activePromotionDialog">
                <Modal.Header>
                    <Modal.Title>{t('withdraw_with_activePromotion_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('withdraw_with_activePromotion_title_content')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="cancel_button" onClick={() => setShowWithdrawActivePromotionDialog(false)}>
                        {t('cancel')}
                    </button>
                    <button className="confirm_button" onClick={() => {
                        // handleSubmit({ forceWithdraw: true });
                        confirmCancelPromotion()
                        setShowWithdrawActivePromotionDialog(false);
                    }}>
                        {t('confirm')}
                    </button>
                </Modal.Footer>
            </Modal> */}

            <Modal show={showTransferGameProviderDialog} onHide={() => setShowTransferGameProviderDialog(false)} centered id="transferGameProviderDialog">
                <Modal.Header>
                    <Modal.Title>{t('transferGameProvider_title')}</Modal.Title>
                    {/* <Modal.Title>{t('reminder')}</Modal.Title> */}

                </Modal.Header>
                <Modal.Body>
                    <p>{t('transferGameProvider_content')}</p>

                </Modal.Body>
                <Modal.Footer>
                    <button className="cancel_button" onClick={() => setShowTransferGameProviderDialog(false)}>
                        {t('cancel')}
                    </button>
                    <button className="confirm_button" onClick={() => {
                        transferGameProvider({ getMethod: transferGameProviderDetails.method, getParams: transferGameProviderDetails.params })
                        setShowTransferGameProviderDialog(false)
                    }}>
                        {t('confirm')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    );




};

// const GameWallet = () => {
//     const appState = useStoreState()
//     const appDispatch = useStoreDispatch()
//     const { t } = useTranslation();
//     const { query, queries, mutation } = useMiddletier()
//     const [isLoading, setLoading] = useState(false);
//     const [alertShow, setAlertShow] = useState({});
//     const [transferState, transferDispatch] = useReducer(transferReducer, initialTransferData)
//     const [restoreProvider, setRestoreProvider] = useState('');

//     const [showWithdrawActivePromotionDialog, setShowWithdrawActivePromotionDialog] = useState(false);
//     const navigate = useNavigate();

//     const isDesktopOrLaptop = useMediaQuery({
//         query: '(min-width: 992px)'
//     })

//     const onClickRefreshGameWalletBalance = () => {
//         setLoading(true)
//         const method = 'getAllGamePrivoderBalanceByMember'

//         query({
//             method,
//             params: [],
//             attributes: []
//         })
//             .then(({ data }) => {

//                 appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
//                 setLoading(false)

//             }).catch((error) => {
//                 console.log('error: ', error)
//                 setLoading(false)
//                 if (error?.networkError?.statusCode === 401) {
//                     appDispatch({ type: APP_STORE_ACTION.LOGOUT })
//                     setAlertShow({ show: true, message: error.toString(), type: 'error' });
//                     navigate('/')
//                 }
//             })
//         // transferDispatch({ type: TRANSFER_ACTION.SET_REFRESH_GAME_WALLET_BALANCE, payload: !transferState.refreshGameWalletBalance })
//     }

//     const allInClick = (game_provider) => {
//         setLoading(true)
//         const method = 'depositGamePrivoderByMember'


//         mutation([{
//             method,
//             params: [
//                 { code: 'gameProvider', graphqlType: 'String', required: true, value: game_provider.code },
//                 { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency },
//                 { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(0) }],
//             attributes: []

//         }]).then(({ data }) => {
//             // IF STATUS IS APPROVED
//             if (data[method].status === 'APPROVED') {
//                 queries([{
//                     index: 'getAllGamePrivoderBalanceByMember',
//                     method: 'getAllGamePrivoderBalanceByMember',
//                     params: [],
//                     attributes: []
//                 },
//                 {
//                     index: 'member',
//                     method: 'member',
//                     params: [
//                         { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
//                     ],
//                     attributes: ['wallet_amount']
//                 },])
//                     .then(({ data }) => {

//                         appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
//                         appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })

//                         setLoading(false)

//                     }).catch((error) => {
//                         console.log('error: ', error)
//                         setLoading(false)
//                         if (error?.networkError?.statusCode === 401) {
//                             appDispatch({ type: APP_STORE_ACTION.LOGOUT })
//                             setAlertShow({ show: true, message: error.toString(), type: 'error' });
//                             navigate('/')
//                         }
//                     })

//             } else {
//                 setLoading(false)
//                 const getErrorMsg = data[method].rejected_reason ? data[method].rejected_reason : (JSON.parse(data[method].response).errMsg)
//                 console.log('getErrorMsg: ', getErrorMsg)
//                 setAlertShow({ show: true, message: getErrorMsg, type: 'error' });
//             }
//         }).catch((error) => {
//             console.log('error: ', error)
//             setLoading(false)
//             if (error?.networkError?.statusCode === 401) {
//                 appDispatch({ type: APP_STORE_ACTION.LOGOUT })
//                 setAlertShow({ show: true, message: error.toString(), type: 'error' });
//                 navigate('/')
//             } else {
//                 setAlertShow({ show: true, message: error.toString(), type: 'error' });
//             }
//         })

//     };

//     const restoreClick = ({ gameProvider, forceWithdraw }) => {
//         setLoading(true)
//         const method = 'withdrawGamePrivoderByMember'
//         const params = [
//             { code: 'gameProvider', graphqlType: 'String', required: true, value: gameProvider ?? restoreProvider },
//             { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency },
//             { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(0) },
//         ]

//         if (forceWithdraw) {
//             params.push({ code: 'forceWithdraw', graphqlType: 'Boolean', required: false, value: true })
//         }

//         mutation([{
//             method,
//             params,
//             attributes: []

//         }]).then(({ data }) => {
//             console.log('withdrawGamePrivoderByMember: ', data[method])
//             queries([{
//                 index: 'getAllGamePrivoderBalanceByMember',
//                 method: 'getAllGamePrivoderBalanceByMember',
//                 params: [],
//                 attributes: []
//             },
//             {
//                 index: 'member',
//                 method: 'member',
//                 params: [
//                     { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
//                 ],
//                 attributes: ['wallet_amount']
//             },])
//                 .then(({ data }) => {

//                     appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
//                     appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })

//                     setLoading(false)

//                 }).catch((error) => {

//                     console.log('error: ', error)
//                     setLoading(false)
//                     if (error?.networkError?.statusCode === 401) {
//                         appDispatch({ type: APP_STORE_ACTION.LOGOUT })
//                         setAlertShow({ show: true, message: error.toString(), type: 'error' });
//                         navigate('/')
//                     }
//                 })


//         }).catch((error) => {

//             console.log('error: ', error)
//             setLoading(false)
//             if (error?.networkError?.statusCode === 401) {
//                 appDispatch({ type: APP_STORE_ACTION.LOGOUT })
//                 setAlertShow({ show: true, message: error.toString(), type: 'error' });
//                 navigate('/')
//             } else if (error.toString().includes("has active promotion")) {
//                 setRestoreProvider(gameProvider)
//                 setShowWithdrawActivePromotionDialog(true);
//             } else {
//                 setAlertShow({ show: true, message: error.toString(), type: 'error' });
//             }
//         })

//     };

//     const formatAmount = (amount, decimalPoint) => {
//         const factor = Math.pow(10, decimalPoint);
//         const truncatedAmount = Math.floor(amount * factor) / factor;
//         const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
//             minimumFractionDigits: decimalPoint,
//             maximumFractionDigits: decimalPoint,
//         });
//         return formattedAmount;
//     }

//     useEffect(() => {
//         const gameWalletCategoryArray = GAME_WALLET_CATEGORY.map((item, index) => {
//             const providers = appState.gameWallet?.reduce((result, gameProvider) => {
//                 gameProvider.type.forEach((t) => {
//                     if (item.indexOf(t) !== -1) {
//                         if (result.indexOf(gameProvider) === -1) {
//                             result.push(gameProvider);
//                         }
//                     }
//                 });
//                 return result;
//             }, []);

//             const types = appState.gameWallet?.reduce((result, gameProvider) => {
//                 gameProvider.type.forEach((t) => {
//                     if (item.indexOf(t) !== -1) {
//                         if (result.indexOf(t) === -1) {
//                             result.push(t);
//                         }
//                     }
//                 });
//                 return result;
//             }, []);

//             return {
//                 type: types,
//                 provider: providers,
//             };
//         });
//         transferDispatch({ type: TRANSFER_ACTION.SET_GAME_WALLETS, payload: gameWalletCategoryArray })

//     }, [appState.gameWallet, transferState.refreshGameWalletBalance])



//     return (
//         <>
//             <div className="wallet_gameWallet" id="wallet_gameWallet">
//                 {isDesktopOrLaptop &&
//                     <>   <div className="font_h3 wallet_gameWallet_title">
//                         {t('game_wallet')}
//                     </div>
//                         <div className="wallet_gameWallet_action_topBar">
//                             <button className="font_button color_secondary wallet_gameWallet_btnRefresh" type="button" onClick={() => onClickRefreshGameWalletBalance()}>
//                                 {t('refresh_balance')}
//                                 <FaSync />
//                             </button>

//                         </div>
//                         <div className="wallet_gameWallet_container">
//                             {transferState.gameWallets.map(function (item, index) {
//                                 return (
//                                     <div className="wallet_gameWallet_row" key={index}>
//                                         <div className="wallet_gameWallet_subTitle">
//                                             {
//                                                 item.type?.map(function (_item, _index) {
//                                                     _item = GAME_MENU_DATA[_item]
//                                                     return (<span key={_index}>{_index !== 0 && '/'} {t(_item.name)} </span>)
//                                                 })
//                                             }
//                                         </div>
//                                         <div className="wallet_gameWallet_wrap">
//                                             {
//                                                 item.provider?.map(function (_item, _index) {
//                                                     return (<div className="wallet_gameWallet_box" key={_item.code}>
//                                                         <div className="d-flex w-100">
//                                                             <div className="wallet_gameWallet_boxContent">
//                                                                 <div className="wallet_gameWallet_gameName">{_item.name}</div>
//                                                                 <div className="wallet_gameWallet_gameImage ">
//                                                                     <img src={`${GLOBAL_BUCKET}/game_provider_logo/${_item.code}.png`} alt="game wallet icon" />
//                                                                 </div>
//                                                             </div>
//                                                             <div className="wallet_gameWallet_boxContent2">
//                                                                 <div className="w-100 mr-1"><input className="wallet_gameWallet_gameBalance" type="text" value={formatAmount(_item.balance ?? 0, appState.currencyFormat.decimal_point)} readOnly /></div>
//                                                                 <div className="p-1"></div>
//                                                                 <div className="wallet_gameWallet_button">
//                                                                     {/* <button className="wallet_gameWallet_btnRestore color_button" type="button" onClick={() => restoreClick({ gameProvider: _item.code, forceWithdraw: false })}>{t('restore')}</button> */}
//                                                                     <button className="wallet_gameWallet_btnAllIn color_button" type="button" onClick={() => allInClick(_item)}>{t('all_in')}</button>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>)
//                                                 })
//                                             }
//                                         </div>

//                                     </div>

//                                 )
//                             })}
//                         </div>
//                     </>}
//                 {!isDesktopOrLaptop && <>
//                    <Accordion defaultActiveKey="0" className=' wallet_gameWallet_accordion' >
//                         <Accordion.Item eventKey="0" className=' wallet_gameWallet_accordion_item' >
//                             <Accordion.Header className="wallet_gameWallet_accordion_title color_button">
//                                 <label>{t('game_wallet')}</label>
//                             </Accordion.Header>
//                             <Accordion.Body className="wallet_gameWallet_accordion_details">
//                                 <div className="wallet_gameWallet_refresh_row">
//                                     <FaSync onClick={() => onClickRefreshGameWalletBalance()} />
//                                 </div>
//                                 {transferState.gameWallets
//                                     .flatMap(({ provider }) => provider)
//                                     .filter((item, index, array) => array.findIndex(obj => obj.id === item.id) === index)
//                                     .map((_item, _index) => {
//                                         return (
//                                             <div className="wallet_gameWallet_accordion_row" key={_item.code}>
//                                                 <div className="wallet_gameWallet_accordion_gameName">{_item.name}</div>
//                                                 <div className="wallet_gameWallet_accordion_gameDetails">
//                                                     <div className="wallet_gameWallet_accordion_gameBalance">{formatAmount(_item.balance ?? 0, appState.currencyFormat.decimal_point)}</div>
//                                                     <button className="wallet_gameWallet_accordion_gameAllIn color_button" type="button" onClick={() => restoreClick({ gameProvider: _item.code, forceWithdraw: false })}>{t('restore')}</button>
//                                                     <button className="wallet_gameWallet_accordion_gameAllIn color_button" type="button" onClick={() => allInClick(_item)}>{t('all_in')}</button>
//                                                 </div>
//                                             </div>

//                                         )
//                                     })}
//                             </Accordion.Body>
//                         </Accordion.Item>
//                     </Accordion> 
//                 </>}
//             </div>
//             {isLoading && (<Loading />)}
//             <Alert show={alertShow.show} onHide={() => setAlertShow({ ...alertShow, show: false })} message={alertShow.message} type={alertShow.type} title={alertShow.title} />
//             <Modal show={showWithdrawActivePromotionDialog} onHide={() => setShowWithdrawActivePromotionDialog(false)} centered id="activePromotionDialog">
//                 <Modal.Header>
//                     <Modal.Title>{t('withdraw_with_activePromotion_title')}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <p>{t('withdraw_with_activePromotion_title_content')}</p>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <button className="cancel_button" onClick={() => setShowWithdrawActivePromotionDialog(false)}>
//                         {t('cancel')}
//                     </button>
//                     <button className="confirm_button" onClick={() => {
//                         restoreClick({ forceWithdraw: true });
//                         setShowWithdrawActivePromotionDialog(false);
//                     }}>
//                         {t('confirm')}
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//         </>

//     );
// }



export default Transfer;
